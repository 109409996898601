<template>
    <div class="bankBox">
		<div class="bank" v-for="(item,i) in list" :key="i">
			<div>
				<span class="left">姓名：</span>{{item.name}}
			</div>
			<div class="flex_between">
				<div>
					<span class="left">银行卡号：</span>{{item.cardNo}}
				</div>
				<div class="operate">
					<span @click="edit(item)">编辑</span>
					<span @click="delect(item.id)">删除</span>
				</div>
			</div>
			<div>
				<span class="left">开户行：</span>{{item.bankName}}
			</div>
		</div>
		<div class="addBtn" @click="addCard()">+添加常用银行卡</div>
		<Card ref="cardshow" @add="addBank"></Card>
		<EditCard ref="editcardshow" @edit="editBank"></EditCard>
    </div>
</template>

<script>
import Card from './components/card.vue';
import EditCard from './components/edit.vue';

import apiUrl from '@/api/main'
export default {
    components: {Card,EditCard},
    data() {
        return {
					list:[],
					
        };
    },
    computed: {},
    watch: {},
		created() {},
    mounted() {
			this.getData(); // 获取银行卡
		},
    methods: {
		// 编辑
		edit(data){
			this.$refs.editcardshow.open(data);
		},
		// 删除
		delect(id){
			this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
			  confirmButtonText: '确定',
			  cancelButtonText: '取消',
			  type: 'warning'
			}).then(() => {

				let data = {
					ids:id
				}
				apiUrl.lsUserBankremove(data).then((res) => {
					if (res.code == 0) {
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
						this.getData();
					} 
				})

			
			}).catch(() => {
			  this.$message({
				type: 'info',
				message: '已取消删除'
			  });          
			});
		},
		addCard(){
		  this.$refs.cardshow.open()
		},
		// 添加银行卡
		addBank(e){
			this.$refs.cardshow.close();
      let data = {
        userId: localStorage.getItem('userId'),
        userType:0,
				name:e.name,
				cardNo:e.num,
				bankName:e.bank
      }
      apiUrl.lsUserBankadd(data).then((res) => {
        if (res.code == 0) {
          this.getData();
        } 
      })
		},
		// 编辑银行卡
		editBank(e){
			this.$refs.editcardshow.close();
			
      let data = {
				id:e.id,
				name:e.name,
				cardNo:e.num,
				bankName:e.bank
      }
      apiUrl.lsUserBankedit(data).then((res) => {
        if (res.code == 0) {
          this.getData();
        } 
      })
		},
		// 获取银行卡
    getData() {
      let data = {
        userId: localStorage.getItem('userId'),
        userType:0
      }
      apiUrl.lsUserBanklist(data).then((res) => {
        if (res.code == 0) {
          this.list = res.rows;
        } 
      })
    },
	},
   
   
    }
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
	.bankBox{
		padding: 30px 40px;
		background-color: #fff;
		border-radius: 13px;
		-webkit-border-radius: 13px;
		-moz-border-radius: 13px;
		font-size: 18px;
		.bank{
			line-height: 44px;
			border: 1px solid #E6E6E6;
			border-radius: 10px;
			padding: 25px 30px;
			margin-bottom: 24px;
			.left{
				display: inline-block;
				width: 150px;
			}
			.operate{
				color: #305BFE;
				cursor: pointer;
				span:nth-of-type(1){
					margin-right: 60px;
				}
			}
		}
		.addBtn{
			width: 308px;
			height: 56px;
			background: linear-gradient(270deg, #4298FF 0%, #305BFE 100%);
			border-radius: 36px;
			text-align: center;
			line-height: 56px;
			color: #fff;
			margin-top: 80px;
			cursor: pointer;
		}
	}
</style>