<template>
	<div class="diooo">
		<el-dialog
		title="添加银行卡"
		:visible.sync="dialogVisible"
		width="467px"
		:before-close="handleClose"
		center>
			<div class="chooseindex">
				<div class="chooseitem flexbox">
					<div class="rig">
						<div class="flexbox mb-20">
							<span class="left">姓名：</span>  
							<el-input v-model="name" placeholder="请输入姓名"></el-input>
						</div>
						<div class="flexbox">
							<span class="left">银行卡号：</span>  
							<el-input v-model="num" placeholder="请输入银行卡号"></el-input>
						</div>
						<div class="flexbox">
							<span class="left">开户行：</span> 
							<el-input v-model="bank" placeholder="请输入开户行"></el-input>
						</div>
					</div>
				</div>
			</div>
			<div class="btnbox" @click="confirm()">确定</div>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		name: 'index',
		components: {
			
		},
		props: {
			type: {
				type: Number,
				default: 1
			}
		},
		data() {
			return {
				name:'',
				num:'',
				bank:'',
				dialogVisible:false,
				Arr:[
					{choose:false},
					{choose:false}
				],
			}
		},
		mounted() {

		},
		methods: {
			open(){
				this.dialogVisible = true;
			},
			close(){
				this.dialogVisible = false;
			},
			handleClose(){
				this.dialogVisible = false;
			},
			confirm(){
				let that = this
				let data = {
					name:that.name,
					num:that.num,
					bank:that.bank
				}
				if(that.name == ''){
					that.$message({
					  message: '请输入姓名',
					  type: 'error'
					});
					return false;
				}else if(that.num == ''){
					that.$message({
					  message: '请输入卡号',
					  type: 'error'
					});
					return false;					
				}else if(that.bank == ''){
					that.$message({
					  message: '请输入开户行',
					  type: 'error'
					});
					return false;					
				}
				that.$emit('add',data);
				that.name = ''
				that.num = ''
				that.bank = ''
			}
		}
	}
</script>
<style lang="scss">
	.diooo{
		.chooseindex{
			padding: 0 50px;
			.chooseitem{
				border-bottom: 1px solid #D8D8D8;
				padding-bottom: 27px;
				padding-top: 27px;
				.chooseimg{
					width: 17px;
					height: 17px;
					margin-right: 40px;
				}
				.rig{
					.flexbox{
						font-size: 15px;
						font-weight: 500;
						color: #333333;
						margin-bottom: 17px;
						span{
							width: 120px;
							font-size: 15px;
							color: #333333;
							font-weight: 400;
						}
					}
					
				}
			}
			.chooseitem:nth-child(1){
				padding-top: 0px;
			}
			.chooseitem:nth-last-child(1){
				border-bottom: 0px solid transparent;
				padding-bottom: 0px;
			}
		}
		.btnbox{
			width: 187px;
			height: 47px;
			line-height: 47px;
			border-radius: 23px;
			margin: 0 auto;
			margin-top: 67px;
		}
	}
</style>